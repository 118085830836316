import React from "react"
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from "../../components/Common/PageBanner"
import Footer from "../../components/App/Footer"
import ServiceDetailsContent from "../../components/ServiceDetails/ServiceDetailsContent"
import RelatedServices from "../../components/ServiceDetails/RelatedServices"

const Details = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Flujos de aprobación"
        homePageText="Inicio"
        homePageUrl="/"
        activePageText="Flujos de aprobación"
      />
      <ServiceDetailsContent />
      <RelatedServices />
      <Footer />
    </Layout>
  )
}

export default Details
